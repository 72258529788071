<template>
  <v-container>
    <v-row class="align-center justify-center mb-4">
      <v-spacer></v-spacer>
      <DateInput v-model="focus" class="mx-2" />
      <v-btn outlined @click="prev()" class="mx-2">
        <v-icon>mdi-chevron-left</v-icon> vorherige Woche
      </v-btn>
      <v-btn outlined @click="next()" class="mx-2">
        <v-icon>mdi-chevron-right</v-icon> nächste Woche
      </v-btn>
    </v-row>
    <v-card
      v-bind="$attrs"
      v-for="(day, index) in register.days"
      :key="'day' + index"
      class="mb-4"
      :loading="loading"
    >
      <v-btn color="success" fab absolute small right @click="add(day)"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
      <div v-if="day.holiday">
        <v-system-bar color="warning">
          <strong
            ><DateSpan
              long
              :value="[day.holiday.startDate, day.holiday.endDate]"
            ></DateSpan
          ></strong>
        </v-system-bar>
        <v-list dense class="py-0">
          <HolidayItem :value="day.holiday" />
        </v-list>
      </div>
      <template v-else>
        <v-system-bar>
          <strong>
            <DateValue :value="day.date" full></DateValue>
            {{ day.canceledBy ? " – Ausfall" : "" }}
          </strong>
          – {{ teacher.code }}
        </v-system-bar>
        <div
          v-if="day.lessons && day.lessons.length > 0"
          :set="(currentStartTime = '')"
        >
          <template v-for="(item, index) in day.lessons">
            <v-divider
              v-if="currentStartTime != item.startTime"
              :key="'divider' + index"
            ></v-divider>
            <v-list-item
              :key="'item' + index"
              :class="[
                item.status && item.status.code == 'asPlanned'
                  ? ''
                  : lessonStatusColor(item.status),
                'lighten-4',
              ]"
            >
              <v-row>
                <v-col>
                  <v-list-item-subtitle
                    v-if="currentStartTime != item.startTime"
                    >{{ item.startTime }} –
                    {{ item.endTime }}
                  </v-list-item-subtitle>
                </v-col>
                <v-col :set="(currentStartTime = item.startTime)">
                  <v-list-item-title>
                    {{ item.course.code }}
                  </v-list-item-title>
                </v-col>
                <v-col>
                  <v-list-item-subtitle>
                    {{ item.course.teachers.map((el) => el.code).join(", ") }}
                  </v-list-item-subtitle>
                </v-col>
                <v-col>
                  <v-list-item-subtitle>
                    {{ item.rooms.map((el) => el.code).join(",") }}
                  </v-list-item-subtitle>
                </v-col>
                <v-col>
                  <v-list-item-subtitle>
                    {{ item.status.description }}
                    {{ item.comments }}
                  </v-list-item-subtitle>
                </v-col>
                <v-col>
                  <v-btn
                    icon
                    @click="edit(day, item)"
                    v-if="item.status && item.status.code != 'canceledByEvent'"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item>
          </template>
        </div>

        <v-list-item v-else disabled>
          <v-list-item-subtitle>kein Unterricht</v-list-item-subtitle>
        </v-list-item>
      </template>
    </v-card>
    <v-dialog v-model="dialog" max-width="500">
      <EditForm
        :lesson="selectedLesson"
        :date="selectedDate"
        :teacher="teacher"
        @close="dialog = false"
        @update="fetchData()"
      ></EditForm>
    </v-dialog>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import { findMonday, today, addDays } from "common/utils/date.js";
import { lessonStatusColor } from "common/utils/icons.js";

import DateInput from "common/components/DateInput.vue";
import DateSpan from "common/components/DateSpan.vue";
import DateValue from "common/components/DateValue.vue";
import EditForm from "./EditForm.vue";
import HolidayItem from "common/components/HolidayItem.vue";

export default defineComponent({
  name: "TeacherLessonChanges",
  components: { DateInput, DateSpan, DateValue, EditForm, HolidayItem },
  props: ["teacher"],
  data() {
    return {
      dialog: false,
      focus: today(),
      selectedDate: null,
      selectedLesson: {},
      register: {},
      steps: 7,
    };
  },
  watch: {
    startDate() {
      this.fetchData();
    },
    teacher() {
      this.fetchData();
    },
  },
  computed: {
    startDate() {
      return this.findMonday(this.focus);
    },
    endDate() {
      return this.addDays(this.findMonday(this.focus), this.steps - 1);
    },
  },
  methods: {
    addDays,
    lessonStatusColor,
    add(day) {
      this.selectedDate = day.date;
      this.selectedLesson = { id: 0, period: {}, rooms: [], status: {} };
      this.dialog = true;
    },
    edit(day, lesson) {
      this.selectedDate = day.date;
      this.selectedLesson = lesson;
      this.dialog = true;
    },
    findMonday,

    async fetchData() {
      if (!this.startDate || !this.endDate) {
        this.register = { days: [] };
        return;
      }
      this.loading = true;
      if (this.teacher && this.teacher.id) {
        this.register = await this.apiList({
          resource: "register/register",
          query: `teacher=${this.teacher.id}&startDate=${this.startDate}&startTime=${this.startTime}&endDate=${this.endDate}&endTime=${this.endTime}`,
        });
      }
      this.loading = false;
    },
    today,
    prev() {
      this.focus = this.addDays(this.focus, -this.steps);
    },
    next() {
      this.focus = this.addDays(this.focus, this.steps);
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
